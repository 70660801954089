<template>
    <div class="edit-stage">
        <div class="preview-panel">
            <div ref="echartDom" class="echart-dom"></div>
            <div class="ctrl-bar">
                <div class="block">
                    <div class="label">时间区间：</div>
                    <n-date-picker
                        v-model:value="previewDateRange"
                        size="small"
                        class="input"
                        type="datetimerange"
                        clearable
                    ></n-date-picker>
                </div>
                <!-- <div class="block">
                    <div class="label">粒度：</div>
                    <n-select
                        class="input"
                        size="small"
                        style="width: 100px"
                    ></n-select>
                </div> -->
                <n-button
                    size="small"
                    class="submit"
                    type="primary"
                    :loading="refreshLoading"
                    @click="onPreview"
                    >刷新</n-button
                >
            </div>
        </div>
        <div class="panel-query-edit-panel">
            <div class="title">
                <div class="text">Panel</div>
                <n-tag
                    v-if="panelForm.id"
                    style="margin-left: 10px"
                    type="info"
                    size="small"
                    round
                    >id: {{ panelForm.id }}</n-tag
                >
            </div>
            <n-form
                ref="panelFormRef"
                :model="panelForm"
                style="margin-top: 10px"
                size="small"
                label-placement="left"
                :rules="panelFormRules"
            >
                <n-grid :x-gap="10">
                    <n-form-item-gi label="名称" :span="8" path="name">
                        <n-input v-model:value="panelForm.name"></n-input>
                    </n-form-item-gi>
                    <n-form-item-gi label="图表类型" :span="5">
                        <n-select
                            v-model:value="panelForm.style"
                            :options="panelStyleOpts"
                            @update:value="onPanelStyleChange"
                        ></n-select>
                    </n-form-item-gi>
                    <n-form-item-gi label="图例位置" :span="4">
                        <n-select
                            v-model:value="panelForm.legendStyle"
                            :options="legendStyleOpts"
                            @update:value="onLegendStyleChange"
                        ></n-select>
                    </n-form-item-gi>
                    <n-form-item-gi label="Query" :span="7">
                        <n-select
                            v-model:value="panelForm.queryId"
                            :options="queryOpts"
                            @update:value="onPanelBindQuerySelected"
                            :loading="refreshLoading"
                            :consistent-menu-width="false"
                            filterable
                        ></n-select>
                        <n-button
                            size="tiny"
                            type="error"
                            :disabled="
                                !props.panelInfo ||
                                props.panelInfo.queryId === panelForm.queryId
                            "
                            @click="restoreQuerySelectValue"
                            quaternary
                            >还原</n-button
                        >
                    </n-form-item-gi>
                    <n-form-item-gi label="图例名称模板" :span="24">
                        <n-input
                            v-model:value="panelForm.legendNameFormatter"
                            placeholder="可通过定制优化展现，Sample:{name}-{type}"
                            @blur="onLegendNameFormatChange"
                        ></n-input>
                    </n-form-item-gi>
                    <n-form-item-gi
                        v-for="item in filterItems"
                        :label="`默认Filter[${item.key}]`"
                        :span="12"
                    >
                        <n-select
                            v-model:value="panelForm.defaultFilterKVs[item.key]"
                            :options="
                                item.options.map(value => ({
                                    label: value,
                                    value,
                                }))
                            "
                            filterable
                            clearable
                            multiple
                        ></n-select>
                        <n-button
                            style="margin-left: 10px"
                            size="tiny"
                            type="primary"
                            text
                            @click="addToModelFilter(item.key)"
                            >添加至全局</n-button
                        >
                    </n-form-item-gi>
                </n-grid>
            </n-form>
            <div class="submit-block">
                <n-button
                    size="small"
                    type="primary"
                    @click="onPanelSave"
                    :disabled="refreshLoading"
                    :loading="panelSaving"
                    >保存Panel</n-button
                >
            </div>
        </div>
        <div class="query-sub-block">
            <div class="panel-query-edit-panel">
                <div class="title">
                    <div class="text">Query</div>
                    <n-tag
                        v-if="queryForm.id"
                        style="margin-left: 10px"
                        type="success"
                        size="small"
                        round
                        >id:{{ queryForm.id }}
                    </n-tag>
                </div>
                <div class="query-edit-area">
                    <div class="edit">
                        <n-form
                            ref="queryFormRef"
                            :model="queryForm"
                            :rules="queryFormRules"
                            label-placement="left"
                            size="small"
                        >
                            <n-grid :x-gap="10" :y-gap="0">
                                <n-form-item-gi
                                    label="名称"
                                    :span="14"
                                    path="name"
                                >
                                    <n-input
                                        v-model:value="queryForm.name"
                                    ></n-input>
                                </n-form-item-gi>
                                <n-form-item-gi
                                    label="数据源"
                                    :span="10"
                                    path="dataset"
                                >
                                    <n-select
                                        v-model:value="queryForm.dataset"
                                        :options="datasetOpts"
                                        @update:value="onDatasetSelected"
                                        :consistent-menu-width="false"
                                    ></n-select>
                                </n-form-item-gi>
                                <n-form-item-gi
                                    v-if="
                                        queryForm.type !==
                                        'finops-general-analysis-calculation'
                                    "
                                    label="查询"
                                    :span="24"
                                >
                                    <n-input
                                        type="textarea"
                                        v-model:value="queryForm.query"
                                        :rows="9"
                                    ></n-input>
                                </n-form-item-gi>
                                <n-form-item-gi
                                    v-if="queryForm.type === 'prometheus'"
                                    label="Step"
                                    :span="24"
                                >
                                    <n-input
                                        v-model:value="queryForm.step"
                                    ></n-input>
                                </n-form-item-gi>
                                <n-form-item-gi
                                    v-if="
                                        queryForm.type ===
                                        'finops-general-analysis-calculation'
                                    "
                                    label="计算"
                                    :span="24"
                                >
                                    <n-select
                                        style="width: 240px"
                                        v-model:value="
                                            queryForm.calculationState.left
                                        "
                                        :options="queryOpts"
                                        filterable
                                        clearable
                                        @update:value="
                                            onCalculationSelectChange
                                        "
                                    ></n-select>
                                    <n-select
                                        style="width: 80px"
                                        v-model:value="
                                            queryForm.calculationState.op
                                        "
                                        :options="operatorOpts"
                                        filterable
                                        clearable
                                        @update:value="
                                            onCalculationSelectChange
                                        "
                                    ></n-select>
                                    <n-select
                                        style="width: 240px"
                                        v-model:value="
                                            queryForm.calculationState.right
                                        "
                                        :options="queryOpts"
                                        filterable
                                        clearable
                                        @update:value="
                                            onCalculationSelectChange
                                        "
                                    ></n-select>
                                </n-form-item-gi>
                                <n-form-item-gi
                                    v-if="
                                        queryForm.type ===
                                        'finops-general-analysis-calculation'
                                    "
                                    :span="24"
                                >
                                    <template #label>
                                        表达式
                                        <n-button
                                            size="tiny"
                                            circle
                                            text
                                            @click.stop="redirectToDoc"
                                        >
                                            <n-icon>
                                                <HelpFilled />
                                            </n-icon>
                                        </n-button>
                                    </template>
                                    <calculate-editor
                                        v-model:value="queryForm.expression"
                                        :querys="store.queryList"
                                        :model-id="props.modelId"
                                        :height="170"
                                    ></calculate-editor>
                                </n-form-item-gi>
                            </n-grid>
                        </n-form>
                    </div>
                    <div class="list">
                        <div class="filter-bar">
                            <n-input
                                v-model:value="queryFilterWord"
                                size="tiny"
                                style="width: 220px"
                                placeholder="关键字筛选Query"
                            ></n-input>
                            <n-button
                                size="tiny"
                                type="primary"
                                style="width: 40px"
                                @click="resetQueryForm"
                            >
                                <n-icon>
                                    <AddFilled />
                                </n-icon>
                            </n-button>
                        </div>
                        <div class="querys">
                            <n-scrollbar style="height: 240px">
                                <div
                                    v-for="item in queryFilteredList"
                                    class="item"
                                    @click="switchQuery(item)"
                                >
                                    <div class="name">
                                        {{ item.id }}-{{ item.name }}
                                    </div>
                                </div>
                            </n-scrollbar>
                        </div>
                    </div>
                </div>
                <div class="submit-block">
                    <n-button
                        style="margin-right: 4px"
                        size="small"
                        type="primary"
                        :loading="querySaving"
                        :disabled="!queryForm.id"
                        secondary
                        @click="pickQueryToPanel(queryForm)"
                        >应用到Panel</n-button
                    >
                    <n-button
                        size="small"
                        type="primary"
                        :loading="querySaving"
                        @click="onQuerySave"
                        >保存Query</n-button
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
@import '../../../common/common.less';
.edit-stage {
    .preview-panel {
        // position: sticky;
        .echart-dom {
            width: 100%;
            height: 240px;
            padding-top: 10px;
            .standard-border;
            .standard-shadow;
            background-color: #fff;
        }

        .ctrl-bar {
            margin-top: 4px;
            .common-ctrl-bar;
        }
    }

    .panel-query-edit-panel {
        margin-bottom: 10px;

        .title {
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom: 1px solid #eee;
            padding-bottom: 4px;
            padding-top: 10px;
            .text {
                font-weight: bold;
            }
        }

        .submit-block {
            padding-top: 10px;
            text-align: right;
        }

        .query-edit-area {
            display: flex;
            flex-direction: row;

            .edit {
                flex-grow: 1;
                padding: 10px 6px 0 0;
            }

            .list {
                width: 260px;
                padding: 10px 0 0 6px;
                border-left: 1px dashed #eee;

                .filter-bar {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }

                .querys {
                    margin-top: 10px;
                    .standard-border;
                    background-color: #fff;

                    .item {
                        padding: 4px 10px;
                        border-bottom: 1px solid #eee;
                        font-size: 12px;
                        line-height: 22px;
                        cursor: pointer;

                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        &:hover {
                            background-color: #f8f8f8;
                            .quick-select {
                                display: unset;
                            }
                        }

                        .quick-select {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .query-sub-block {
        .standard-border;
        padding: 0 10px;
        background-color: #f0f9ee;
    }
}
</style>

<script setup>
import { ref, computed, watch, onMounted, nextTick } from 'vue';
import {
    NButton,
    NInput,
    NForm,
    NFormItemGi,
    NGrid,
    NSelect,
    NDatePicker,
    NTag,
    NIcon,
    NScrollbar,
    useMessage,
} from 'naive-ui';
import { AddFilled, HelpFilled } from '@vicons/material';

import { isEmptyArray } from '@/common/tools';
import { CostNervAPI } from '@/common/API';
import { useModelEditorStore } from '@/stores/cost_nerv/ModelEditor';
import { defaultDateRangeMap, AnalysisChart } from './tools';
import CalculateEditor from './CalculateEditor';

const store = useModelEditorStore();

const props = defineProps([
    'modelId',
    'panelInfo',
    'defaultDateRangeType',
    'todayOffset',
]);

const emits = defineEmits(['filterPush']);

const message = useMessage();

/**
 * refresh
 */

let queryCalData = ref(null);

let refreshLoading = ref(false);

function refreshData() {
    if (refreshLoading.value) {
        return;
    }
    refreshLoading.value = true;
    CostNervAPI.calculateQuery(
        props.modelId,
        panelForm.value.queryId,
        previewDateRange.value[0] / 1000,
        previewDateRange.value[1] / 1000,
        '',
        previewFilterKVQuerys.value
    )
        .then(res => {
            refreshLoading.value = false;
            if (res.error_no !== 0) {
                message.error(res.error_msg || '未知异常导致加载失败');
                return;
            }
            queryCalData.value = res.data;
        })
        .catch(err => {
            refreshLoading.value = false;
            message.error('未知异常导致加载失败');
            console.log(err);
        });
}

watch(queryCalData, value => {
    aChartInstance.setData(value).render();
    setPanelFilters(value);
});

/**
 * Panel Form
 */
let panelFormRef = ref();

let panelStyleOpts = ref([
    { label: '折线图', value: 'line' },
    { label: '柱状图', value: 'bar' },
]);

let legendStyleOpts = ref([
    { label: '右侧', value: 'right' },
    { label: '底部', value: 'bottom' },
    { label: '隐藏', value: 'none' },
]);

let panelForm = ref({
    name: '',
    queryId: null,
    style: 'line',
    legendStyle: 'right',
    filters: null,
    defaultFilterKVs: {},
    legendNameFormatter: '',
});

let panelFormRules = ref({
    name: {
        required: true,
        message: '请填写Panel名称',
        trigger: ['blur', 'submit'],
    },
});

let filterItems = computed(() => {
    return Object.keys(panelForm.value.filters || {}).map(key => ({
        key,
        options: panelForm.value.filters[key],
    }));
});

function onPanelStyleChange() {
    aChartInstance.setStyle(panelForm.value.style).render();
}

function onLegendStyleChange() {
    aChartInstance.setLegendStyle(panelForm.value.legendStyle).render();
}

function onLegendNameFormatChange() {
    aChartInstance
        .setLegendNameFormat(panelForm.value.legendNameFormatter)
        .render();
}

function addToModelFilter(key) {
    emits('filterPush', key, panelForm.value.filters[key]);
    message.success('已添加至全局Filter列表，需要保存模型以应用');
}

function setPanelFilters(data) {
    panelForm.value.filters = data.filters;
    checkFilters();
}

function onPanelBindQuerySelected(value) {
    if (refreshLoading.value) {
        return;
    }
    refreshData();
}

function restoreQuerySelectValue() {
    panelForm.value.queryId = props.panelInfo.queryId;
    onPanelBindQuerySelected(panelForm.value.queryId);
}

function checkFilters() {
    for (let key in panelForm.value.defaultFilterKVs) {
        if (!panelForm.value.filters[key]) {
            delete panelForm.value.defaultFilterKVs[key];
        }
    }
}

let panelSaving = ref(false);

function onPanelSave() {
    panelFormRef.value.validate(errs => {
        if (errs) {
            console.log(errs);
            return;
        }
        panelSaving.value = true;
        checkFilters();
        let panel = panelForm.value;
        let task = panel.id
            ? CostNervAPI.updatePanel(props.modelId, panel.id, panel)
            : CostNervAPI.createPanel(props.modelId, panel);
        task.then(res => {
            panelSaving.value = false;
            if (res.error_no !== 0) {
                message.error(res.error_msg || '未知异常导致加载失败');
                return;
            }
            message.success('操作完成');
            if (!panel.id) {
                panelForm.value.id = res.data;
            }
            store.refresh(props.modelId, 'panel');
            refreshData();
        });
    });
}

/**
 * Query Form
 */

let queryFormRef = ref();

let operatorOpts = ref([
    { label: '+', value: '+' },
    { label: '-', value: '-' },
    { label: '*', value: '*' },
    { label: '/', value: '/' },
]);

let queryOpts = computed(() =>
    store.queryList.map(item => ({ label: item.name, value: item.id + '' }))
);

let queryFormRules = ref({
    name: {
        required: true,
        message: '请填写Query名称',
        trigger: ['blur', 'submit'],
    },
    dataset: {
        required: true,
        message: '请选择数据源',
        trigger: ['blur', 'submit'],
    },
});

let queryForm = ref({
    name: '',
    dataset: '',
    type: '',
    query: '',
    step: '',
    filter: '{}',
    expression: '',
    calculationState: {
        left: '',
        op: '',
        right: '',
    },
});

function onDatasetSelected(value) {
    queryForm.value.type = datasetTypeMap[value];
}

function onCalculationSelectChange() {
    queryForm.value.query = `{${props.modelId},${queryForm.value.calculationState.left}} ${queryForm.value.calculationState.op} {${props.modelId},${queryForm.value.calculationState.right}}`;
}

function redirectToDoc() {
    window.open(
        'https://confluence.mobvista.com/pages/viewpage.action?pageId=89811217'
    );
}

let queryFilterWord = ref('');

let queryFilteredList = computed(() => {
    if (queryFilterWord.value === '') {
        return store.queryList;
    }
    return store.queryList.filter(item =>
        item.name.toLowerCase().includes(queryFilterWord.value)
    );
});

function switchQuery(item) {
    queryForm.value = { ...queryForm.value, ...item };
}

function pickQueryToPanel(item) {
    panelForm.value.queryId = item.id + '';
    onPanelBindQuerySelected(item.id);
}

function resetQueryForm() {
    queryForm.value = {
        name: '',
        dataset: '',
        type: '',
        query: '',
        step: '',
        filter: '{}',
        expression: '',
        calculationState: {
            left: '',
            op: '',
            right: '',
        },
    };
}

let querySaving = ref(false);

function onQuerySave() {
    queryFormRef.value.validate(errs => {
        if (errs) {
            return;
        }
        querySaving.value = true;
        let query = queryForm.value;

        let task = query.id
            ? CostNervAPI.updateQuery(props.modelId, query.id, query)
            : CostNervAPI.createQuery(props.modelId, query);

        task.then(res => {
            querySaving.value = false;
            if (res.error_no !== 0) {
                message.error(res.error_msg || '未知异常导致加载失败');
                return;
            }
            message.success('操作完成');
            if (!query.id) {
                queryForm.value.id = res.data;
            }
            store.refresh(props.modelId, 'query');
            if (panelForm.value.queryId) {
                refreshData();
            }
        }).catch(err => {
            querySaving.value = false;
            message.error('未知异常导致加载失败');
        });
    });
}

/**
 * Preview
 */

let previewDateRange = ref(
    defaultDateRangeMap[props.defaultDateRangeType]
        .range(props.todayOffset)
        .map(value => value * 1000)
);

let previewFilterKVQuerys = computed(() => {
    let querys = {};
    for (let key in panelForm.value.defaultFilterKVs) {
        if (!isEmptyArray(panelForm.value.defaultFilterKVs[key])) {
            querys[key] = panelForm.value.defaultFilterKVs[key];
        }
    }
    return querys;
});

let echartDom = ref();

let aChartInstance = null;

function onPreview() {
    aChartInstance && aChartInstance.clear();
    if (!panelForm.value.queryId) {
        message.error('未在Panel中指定预览Query');
        return;
    }
    refreshData();
}

/**
 * Datasets
 */

let datasetOpts = ref([]);
let datasetTypeMap = {};

function loadDataSets() {
    CostNervAPI.getDataSets().then(res => {
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        datasetOpts.value = res.data.map(item => {
            datasetTypeMap[item.name] = item.type;
            return {
                label: `${item.label}[${item.type}]`,
                value: item.name,
            };
        });
    });
}

loadDataSets();

onMounted(() => {
    aChartInstance = new AnalysisChart(echartDom.value);
    // 传null表示新建Panel
    if (!props.panelInfo) {
        return;
    }

    panelForm.value = { ...panelForm.value, ...props.panelInfo };
    queryForm.value = {
        ...queryForm.value,
        ...store.queryList.find(item => item.id === +panelForm.value.queryId),
    };
    nextTick(() => {
        aChartInstance.setStylesByPanelInfo(panelForm.value);
        refreshData();
    });
});
</script>
