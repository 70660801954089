<template>
    <div
        :class="{ 'calculate-editor-view': true, focus: isFocus }"
        :style="{ height: props.height + 'px' }">
        <n-scrollbar class="scrollbar">
            <div ref="editorRef"></div>
        </n-scrollbar>
    </div>
</template>

<style lang="less" scoped>
@import '../../../common/common.less';
.calculate-editor-view {
    background-color: #fff;
    border: 1px solid @standard-border-color;
    border-radius: 4px;
    width: 100%;
    padding: 0;
    overflow: hidden;
    transition: border-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    &.focus {
        border-color: @primary-color;
        box-shadow: 0px 0px 1px 1px #0b6ee240;
    }

    &:hover {
        border-color: @primary-color;
        cursor: text;
    }

    .scrollbar {
        max-height: 100%;
    }
}
</style>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { basicSetup, EditorView } from 'codemirror';
import { autocompletion } from '@codemirror/autocomplete';
import { linter, lintGutter } from '@codemirror/lint';
import { NScrollbar } from 'naive-ui';

const props = defineProps({
    value: {
        type: String,
    },
    querys: {
        type: Array,
        default: () => [],
    },
    modelId: {
        type: String,
    },
    height: {
        type: Number,
        default: 200,
    },
});

watch(
    () => props.value,
    val => {
        if (val === editor.state.doc.toString()) {
            return;
        }
        editor.dispatch({
            changes: { from: 0, to: editor.state.doc.length, insert: val },
        });
    }
);

const emits = defineEmits(['update:value']);

let editorRef = ref();

let completionFunctions = [
    {
        label: 'sum',
        type: 'function',
        apply: 'sum()',
        detail: '将「对象」中所有的“线”进行加总',
    },
    {
        label: 'mean',
        type: 'function',
        apply: 'mean()',
        detail: '将「对象」中所有的“线”进行平均',
    },
    {
        label: 'combine',
        type: 'function',
        apply: 'combine()',
        detail: '将多个「对象」组装成一个对象',
    },
    {
        label: 'merge',
        type: 'function',
        apply: 'merge()',
        detail: '将多个「对象」合并成一个对象',
    },
    {
        label: 'shift',
        type: 'function',
        apply: 'shift(periods,freq)',
        detail: '将对象在时间轴上向右平移',
    },
    {
        label: 'rolling',
        type: 'function',
        apply: 'rolling(window)',
        detail: '平滑曲线',
    },
    {
        label: 'resample',
        type: 'function',
        apply: 'resample(rule,fun)',
        detail: '重新采样',
    },
    {
        label: 'rename',
        type: 'function',
        apply: 'rename(new_name)',
        detail: '重命名',
    },
    {
        label: 'attr',
        type: 'function',
        apply: 'attr(key,value)',
        detail: '追加属性',
    },
    {
        label: 'cumsum',
        type: 'function',
        apply: 'cumsum()',
        detail: '将「对象」的曲线沿时间轴方向进行累积求和',
    },
];

let completionQuerys = computed(() => {
    return props.querys.map(item => ({
        label: `{${item.id}:${item.name}}`,
        type: 'text',
        apply: `{${props.modelId},${item.id}`,
    }));
});

function nervCompletions(context) {
    let queryMatch = context.matchBefore(/\{/);
    let dotMatch = context.matchBefore(/\./);
    let before = context.matchBefore(/\w+/);

    if (!context.explicit && !queryMatch && !dotMatch && !before) {
        return null;
    }

    if (queryMatch && queryMatch.text === '{') {
        return {
            from: queryMatch ? queryMatch.from : context.pos,
            options: completionQuerys.value,
            validFor: /\{[\w\s:_]+/,
        };
    }

    if (dotMatch && dotMatch.text === '.') {
        return {
            from: context.pos,
            options: completionFunctions,
            validFor: /\.[\w]+/,
        };
    }

    return {
        from: before ? before.from : context.pos,
        options: completionFunctions,
        validFor: /^\w*$/,
    };
}

let queryLabelRegex = /\{[0-9]*,[0-9]*\}/g;

function queryLint(view) {
    let matchesLabel = [];
    let prevLength = 0;
    let viewDoc = view.state.doc;
    let docIsTextNode = viewDoc.children && viewDoc.children.length > 0;
    let docIsTextLeaf = !!viewDoc.text;

    function setMatchesLabel(text) {
        text.forEach(txt => {
            let matches = [...txt.matchAll(queryLabelRegex)];
            if (!matches || matches.length < 1) {
                return;
            }
            matches.forEach(matchItem => {
                let mtxt = matchItem[0];
                let idxOf = matchItem.index;
                matchesLabel.push({
                    label: mtxt,
                    from: idxOf + prevLength,
                    to: idxOf + mtxt.length + prevLength,
                });
            });
            prevLength += txt.length + 1;
        });
    }

    if (docIsTextLeaf) {
        setMatchesLabel(viewDoc.text);
    } else if (docIsTextNode) {
        viewDoc.children.forEach(textLeaf => setMatchesLabel(textLeaf.text));
    }
    return matchesLabel.map(item => {
        let queryInfo = completionQuerys.value.find(
            query => query.apply + '}' === item.label
        );
        return {
            from: item.from,
            to: item.to,
            severity: queryInfo ? 'info' : 'warning',
            message: queryInfo
                ? queryInfo.label.replace(/\{(.*)\}/, '$1')
                : '未知Query，请确认是否可用',
        };
    });
}

let isFocus = ref(false);

let editor = null;

onMounted(() => {
    editor = new EditorView({
        doc: props.value,
        extensions: [
            basicSetup,
            autocompletion({ override: [nervCompletions] }),
            lintGutter(),
            linter(queryLint),
            EditorView.updateListener.of(v => {
                if (v.docChanged) {
                    emits('update:value', v.state.doc.toString());
                }
            }),
            EditorView.focusChangeEffect.of((state, focus) => {
                isFocus.value = focus;
            }),
            // EditorView.domEventHandlers({
            //     focus(event, view) {
            //         console.log(view);
            //     },
            // }),
        ],
        parent: editorRef.value,
    });
});
</script>
