<template>
    <div class="model-editor-view-v2">
        <PageTitle name="编辑模型" :back-action="goBack">
            <template #suffix>
                <div class="page-title-suffix">
                    <n-tooltip placement="bottom">
                        <template #trigger>
                            <n-button size="small" tag="div" disabled>
                                预览
                            </n-button>
                        </template>
                        开发中，敬请期待
                    </n-tooltip>
                    <n-button
                        type="primary"
                        size="small"
                        style="margin-left: 4px"
                        :loading="loading"
                        @click="saveModel"
                        >保存</n-button
                    >
                </div>
            </template>
        </PageTitle>
        <div class="model-info-panel">
            <div class="label">模型名称：</div>
            <n-input
                size="small"
                class="input"
                v-model:value="modelInfo.name"
                style="width: 300px"
            ></n-input>
            <div class="label">默认时间区间：</div>
            <n-select
                class="input"
                v-model:value="modelInfo.default.dateRangeType"
                :options="dateRangeOpts"
                size="small"
                style="width: 100px"
            ></n-select>
            <div class="label">日期偏移：</div>
            <n-input-number
                v-model:value="modelInfo.default.todayOffset"
                size="small"
                class="input"
                style="width: 80px"
            ></n-input-number>
            <n-button
                class="submit"
                size="small"
                @click="isModelFilterManagerShow = true"
                >全局Filter管理</n-button
            >
        </div>
        <div
            class="edit-area"
            :style="{ height: editorViewHeight - 72 + 'px' }"
        >
            <PanelQueryList
                :model-id="route.params.id"
                @panel-click="onPanelEdit"
            />
            <div class="layout-editor-panel">
                <div v-for="(section, sIdx) in layoutData" class="section">
                    <div class="label">
                        <n-icon class="icon">
                            <LabelFilled />
                        </n-icon>
                        <div class="label-text">
                            {{ section.label }}
                        </div>
                        <n-popover trigger="click" placement="bottom">
                            <template #trigger>
                                <n-button size="tiny" quaternary>
                                    <n-icon>
                                        <EditFilled />
                                    </n-icon>
                                </n-button>
                            </template>
                            <n-input
                                size="tiny"
                                v-model:value="section.label"
                            ></n-input>
                        </n-popover>

                        <div class="ctrl">
                            <n-button
                                size="tiny"
                                quaternary
                                @click="moveSection(sIdx, sIdx - 1)"
                                :disabled="sIdx < 1"
                            >
                                <n-icon>
                                    <ArrowUpwardFilled />
                                </n-icon>
                            </n-button>
                            <n-button
                                size="tiny"
                                quaternary
                                :disabled="sIdx === layoutData.length - 1"
                                @click="moveSection(sIdx, sIdx + 1)"
                            >
                                <n-icon>
                                    <ArrowDownwardFilled />
                                </n-icon>
                            </n-button>
                            <n-popconfirm
                                trigger="click"
                                placement="bottom"
                                @positive-click="deleteSection(sIdx)"
                            >
                                <template #trigger>
                                    <n-button size="tiny" quaternary>
                                        <n-icon>
                                            <DeleteFilled />
                                        </n-icon>
                                    </n-button>
                                </template>
                                确定删除该Section？
                            </n-popconfirm>
                        </div>
                    </div>
                    <div class="rows">
                        <div v-for="(rows, rIdx) in section.rows" class="row">
                            <div
                                v-for="(column, cIdx) in rows.columns"
                                class="column"
                            >
                                <PanelColumn
                                    class="panel"
                                    v-model:id="column.data"
                                    @panel-click="onPanelEdit"
                                />
                            </div>
                            <n-popover
                                placement="left"
                                trigger="manual"
                                :show="
                                    popShowStateMap[
                                        'ctrlRow_' + sIdx + '_' + rIdx
                                    ]
                                "
                                @clickoutside="
                                    setPopShowState(
                                        'ctrlRow_' + sIdx + '_' + rIdx,
                                        false
                                    )
                                "
                            >
                                <template #trigger>
                                    <div
                                        class="row-ctrl"
                                        @click="
                                            setPopShowState(
                                                'ctrlRow_' + sIdx + '_' + rIdx,
                                                true
                                            )
                                        "
                                    >
                                        <n-button text>
                                            <n-icon
                                                style="
                                                    margin-top: 30px;
                                                    margin: 30px 0 0 4px;
                                                "
                                            >
                                                <MoreVertFilled />
                                            </n-icon>
                                        </n-button>
                                    </div>
                                </template>
                                <n-button-group size="tiny">
                                    <n-button
                                        :disabled="rIdx < 1"
                                        @click="moveRow(sIdx, rIdx, rIdx - 1)"
                                    >
                                        <n-icon>
                                            <ArrowUpwardFilled />
                                        </n-icon>
                                    </n-button>
                                    <n-button
                                        :disabled="
                                            rIdx === section.rows.length - 1
                                        "
                                        @click="moveRow(sIdx, rIdx, rIdx + 1)"
                                    >
                                        <n-icon>
                                            <ArrowDownwardFilled />
                                        </n-icon>
                                    </n-button>
                                    <n-popconfirm
                                        trigger="click"
                                        placement="left"
                                        @positive-click="deleteRow(sIdx, rIdx)"
                                    >
                                        <template #trigger>
                                            <n-button>
                                                <n-icon>
                                                    <DeleteFilled />
                                                </n-icon>
                                            </n-button>
                                        </template>
                                        <div style="width: 120px">
                                            确定删除该Row？
                                        </div>
                                    </n-popconfirm>
                                </n-button-group>
                            </n-popover>
                        </div>
                        <n-popover
                            :show="popShowStateMap['addRow_' + sIdx]"
                            placement="bottom"
                            trigger="manual"
                            @clickoutside="
                                setPopShowState('addRow_' + sIdx, false)
                            "
                        >
                            <template #trigger>
                                <n-button
                                    type="info"
                                    size="tiny"
                                    block
                                    @click="
                                        setPopShowState('addRow_' + sIdx, true)
                                    "
                                    >+ Row</n-button
                                >
                            </template>
                            <div>
                                <n-button-group>
                                    <n-button @click="addRow(sIdx, 1)"
                                        >One Column</n-button
                                    >
                                    <n-button @click="addRow(sIdx, 2)"
                                        >Two Column(1:1)</n-button
                                    >
                                    <n-button @click="addRow(sIdx, 3)"
                                        >Three Column(1:1:1)</n-button
                                    >
                                </n-button-group>
                            </div>
                        </n-popover>
                    </div>
                </div>
                <n-button
                    size="large"
                    type="primary"
                    round
                    block
                    ghost
                    @click="addSection"
                    >+ Section</n-button
                >
            </div>
        </div>
        <!-- Modal -->
        <n-modal v-model:show="isModelFilterManagerShow">
            <n-card
                class="model-filter-card"
                title="全局Filters管理"
                size="small"
            >
                <div v-if="modelFilterItems.length < 1" class="no-content">
                    无Filter
                </div>
                <n-form size="small">
                    <n-form-item
                        v-for="item in modelFilterItems"
                        :label="item.key"
                    >
                        <n-select
                            v-model:value="
                                modelInfo.default.filterKVs[item.key]
                            "
                            :options="
                                item.options.map(value => ({
                                    label: value,
                                    value,
                                }))
                            "
                            filterable
                            clearable
                            multiple
                        ></n-select>
                        <n-button
                            style="margin-left: 10px"
                            size="tiny"
                            quaternary
                            @click="deleteModelFilter(item.key)"
                        >
                            <template #icon>
                                <n-icon>
                                    <DeleteFilled />
                                </n-icon>
                            </template>
                        </n-button>
                    </n-form-item>
                </n-form>
            </n-card>
        </n-modal>
        <n-drawer v-model:show="editStageShow" placement="right" width="78vw">
            <n-drawer-content
                title="内容编辑工作台"
                :body-style="{ backgroundColor: '#f8fbff' }"
            >
                <EditStage
                    :model-id="route.params.id"
                    :panel-info="selectPanel"
                    :default-date-range-type="modelInfo.default.dateRangeType"
                    :today-offset="modelInfo.default.todayOffset"
                    @filter-push="pushModelFilter"
                />
            </n-drawer-content>
        </n-drawer>
        <!-- <n-drawer>
            <n-drawer-content
                title="预览"
                :body-style="{ backgroundColor: '#f8fbff' }"
                >hello</n-drawer-content
            >
        </n-drawer> -->
    </div>
</template>

<style lang="less" scoped>
@import '../../common/common.less';

.model-editor-view-v2 {
    .common-content;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    .page-title-suffix {
        position: absolute;
        right: 0;
        top: 0;
        width: 400px;
        display: flex;
        flex-direction: row;
        justify-content: right;
    }

    .model-info-panel {
        .common-ctrl-bar;
    }

    .edit-area {
        margin-top: 10px;
        flex-grow: 1;

        display: flex;
        flex-direction: row;

        .layout-editor-panel {
            flex-grow: 1;
            padding: 10px;
            overflow-y: auto;
            .section {
                width: 100%;
                margin-bottom: 10px;
                .label {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .icon {
                        margin-right: 6px;
                    }

                    .label-text {
                        font-weight: bold;
                        font-size: 16px;
                    }

                    .ctrl {
                        margin-left: auto;
                    }
                }

                .rows {
                    margin-top: 4px;

                    .row {
                        position: relative;
                        background-color: #e1e1e1;
                        margin-bottom: 4px;
                        padding: 4px;

                        display: flex;
                        flex-direction: row;
                        justify-content: space-around;

                        .column {
                            background-color: #fff;
                            margin-right: 4px;
                            width: 30%;
                            height: 70px;
                            flex-grow: 1;

                            &:hover {
                                background-color: #f8f8f8;
                            }

                            &:last-child {
                                margin-right: 0px;
                            }

                            .panel {
                                margin: 6px 10px;
                            }
                        }

                        .column-2c {
                            width: 50%;
                        }

                        .row-ctrl {
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 20px;
                            height: 100%;
                            background-color: #ccc;
                            display: none;
                        }

                        &:hover {
                            .row-ctrl {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}

.model-filter-card {
    width: 500px;

    .no-content {
        text-align: center;
        padding: 20px 0;
        color: #999;
    }
}
</style>

<script setup>
import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import {
    NIcon,
    NButton,
    NButtonGroup,
    NPopover,
    NPopconfirm,
    NInput,
    NInputNumber,
    NSelect,
    NModal,
    NCard,
    NForm,
    NFormItem,
    NDrawer,
    NDrawerContent,
    NTooltip,
    useMessage,
} from 'naive-ui';

import {
    MoreVertFilled,
    ArrowUpwardFilled,
    ArrowDownwardFilled,
    DeleteFilled,
    LabelFilled,
    EditFilled,
} from '@vicons/material';

import PageTitle from '@/components/PageTitle';
import PanelQueryList from './components/PanelQueryList';
import PanelColumn from './components/PanelColumn';
import EditStage from './components/EditStage';

import { useModelEditorStore } from '@/stores/cost_nerv/ModelEditor';

import { CostNervAPI } from '@/common/API';
import { isNullOrUndefined } from '@/common/tools';
import { defaultDateRangeMap } from './components/tools';

const store = useModelEditorStore();

const route = useRoute();

const router = useRouter();

const message = useMessage();

let editorViewHeight = ref(
    document.querySelector('.app-container').clientHeight - 80
);

/**
 * layout system
 */

function initLayoutData() {
    return [
        {
            label: 'Section 1',
            rows: [
                {
                    height: 0,
                    columns: [{ style: 'normal', data: null }],
                },
            ],
        },
    ];
}

let layoutData = ref([]);

function addSection() {
    layoutData.value.push({
        label: `Section ${layoutData.value.length + 1}`,
        rows: [
            {
                height: 0,
                columns: [{ style: 'normal', data: null }],
            },
        ],
    });
}

function moveSection(index, toIndex) {
    if (toIndex < 0 || toIndex > layoutData.value.length - 1) {
        return;
    }
    let sectionItem = layoutData.value[index];
    layoutData.value[index] = layoutData.value[toIndex];
    layoutData.value[toIndex] = sectionItem;
}

function deleteSection(index) {
    layoutData.value = layoutData.value.filter((section, i) => {
        return i !== index;
    });
}

function addRow(sectionIndex, colCount) {
    function genCols(count) {
        let cols = [];
        for (let i = 0; i < count; i++) {
            cols.push({ style: 'normal', data: null });
        }
        return cols;
    }
    layoutData.value[sectionIndex].rows.push({
        height: 0,
        columns: genCols(colCount),
    });
    setPopShowState('addRow_' + sectionIndex, false);
}

function moveRow(sectionIndex, selectRowIndex, toRowIndex) {
    if (
        toRowIndex < 0 ||
        toRowIndex > layoutData.value[sectionIndex].rows.length - 1
    ) {
        return;
    }
    let row = layoutData.value[sectionIndex].rows[selectRowIndex];
    layoutData.value[sectionIndex].rows[selectRowIndex] =
        layoutData.value[sectionIndex].rows[toRowIndex];
    layoutData.value[sectionIndex].rows[toRowIndex] = row;
    setPopShowState('ctrlRow_' + sectionIndex + '_' + selectRowIndex, false);
}

function deleteRow(sectionIndex, rowIndex) {
    layoutData.value[sectionIndex].rows = layoutData.value[
        sectionIndex
    ].rows.filter((row, i) => {
        return i !== rowIndex;
    });
    setPopShowState('ctrlRow_' + sectionIndex + '_' + rowIndex, false);
}

let popShowStateMap = ref({});

function setPopShowState(key, value) {
    popShowStateMap.value[key] = value;
}

/**
 * model
 */

let loading = ref(false);

let dateRangeOpts = ref(
    Object.keys(defaultDateRangeMap).map(key => ({
        label: defaultDateRangeMap[key].label,
        value: key,
    }))
);

let modelInfo = ref({
    name: '',
    creator: '',
    default: { dateRangeType: 'last7', todayOffset: 0, filterKVs: {} },
    filters: {},
});

function loadModel() {
    loading.value = true;
    CostNervAPI.getModel(route.params.id).then(res => {
        loading.value = false;
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }

        let defaultGetter = (
            obj => (key, defaultVal) =>
                !obj || isNullOrUndefined(obj[key]) ? defaultVal : obj[key]
        )(res.data.default);

        modelInfo.value = {
            name: res.data.name,
            creator: res.data.creator,
            default: {
                dateRangeType: defaultGetter('dateRangeType', 'last7'),
                todayOffset: defaultGetter('todayOffset', 0),
                filterKVs: defaultGetter('filterKVs', {}),
            },
            filters: res.data.filters || {},
        };
        layoutData.value = res.data.layoutV1 || initLayoutData();
    });
}

function saveModel() {
    loading.value = true;
    CostNervAPI.updateModel(route.params.id, {
        ...modelInfo.value,
        layoutV1: layoutData.value,
    }).then(res => {
        loading.value = false;
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        message.success('保存成功！');
    });
}

function goBack() {
    router.replace(`/cost_nerv/model/${route.params.id}`);
}

loadModel();
store.load(route.params.id);

/**
 *  Model Filter Manage
 */

let isModelFilterManagerShow = ref(false);

let modelFilterItems = computed(() => {
    return Object.keys(modelInfo.value.filters).map(key => ({
        key,
        options: modelInfo.value.filters[key],
    }));
});

function deleteModelFilter(key) {
    delete modelInfo.value.filters[key];
    delete modelInfo.value.default.filterKVs[key];
}

function pushModelFilter(key, value) {
    modelInfo.value.filters[key] = value;
}

// onMounted(() => {});

/**
 * Edit Stage
 */

let editStageShow = ref(false);

let selectPanel = ref(null);

function onPanelEdit(item) {
    selectPanel.value = item;
    editStageShow.value = true;
}

/**
 * Preivew Model
 */
</script>
