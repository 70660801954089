/**
 * @file Cost Nerv模型编辑全局共享状态
 */

import { defineStore } from 'pinia';

import { CostNervAPI } from '@/common/API';

export const useModelEditorStore = defineStore('modelEditor', {
    state: () => ({
        panel: {
            list: [],
            loading: false,
        },
        query: {
            list: [],
            loading: false,
        },
    }),
    getters: {
        panelLoading(state) {
            return state.panel.loading;
        },
        queryLoading(state) {
            return state.query.loading;
        },
        panelList(state) {
            return state.panel.list;
        },
        queryList(state) {
            return state.query.list;
        },
    },
    actions: {
        load(modelId) {
            this.refresh(modelId, 'query');
            this.refresh(modelId, 'panel');
        },
        refresh(modelId, source) {
            this[source].loading = true;
            let task =
                source === 'query'
                    ? CostNervAPI.getQueryList(modelId)
                    : CostNervAPI.getPanelList(modelId);
            task.then(res => {
                this[source].loading = false;
                if (res.error_no !== 0) {
                    return;
                }
                this[source].list = res.data.list;
            });
        },
    },
});
