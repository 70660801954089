<template>
    <div class="panel-query-list-view">
        <n-tabs type="line" size="small" justify-content="space-around">
            <template #suffix>
                <n-button
                    type="primary"
                    size="small"
                    @click="showEditStage(null)"
                    ghost
                >
                    <n-icon>
                        <AddFilled />
                    </n-icon>
                </n-button>
            </template>
            <n-tab-pane name="panel" tab="Panel">
                <div class="filter-bar-view">
                    <n-input
                        v-model:value="panelFilterWord"
                        size="tiny"
                        placeholder="输入关键字筛选"
                    ></n-input>
                </div>
                <n-scrollbar
                    class="list"
                    :style="{
                        maxHeight: scrollHeight + 'px',
                    }"
                >
                    <div
                        class="item"
                        v-for="item in panelFilteredList"
                        draggable="true"
                        @dragstart="e => onPanelDragStart(e, item)"
                        @dragend="onPanelDragEnd"
                        @click="showEditStage(item)"
                    >
                        <n-icon style="margin-right: 4px">
                            <InsertChartFilled />
                        </n-icon>
                        <div class="label" :title="item.name">
                            {{ item.name }}
                        </div>
                        <n-popconfirm
                            trigger="click"
                            placement="right"
                            @positive-click="deletePanel(item.id)"
                        >
                            <template #trigger>
                                <n-button
                                    class="delete-btn"
                                    size="tiny"
                                    quaternary
                                    style="margin-left: auto"
                                    @click.stop
                                >
                                    <n-icon>
                                        <DeleteFilled />
                                    </n-icon>
                                </n-button>
                            </template>
                            确定删除该Panel?（该操作不可撤销）
                        </n-popconfirm>
                    </div>
                </n-scrollbar>
            </n-tab-pane>
            <n-tab-pane name="query" tab="Query">
                <div class="filter-bar-view">
                    <n-input
                        v-model:value="queryFilterWord"
                        size="tiny"
                        placeholder="输入关键字筛选"
                    ></n-input>
                </div>
                <n-scrollbar
                    class="list"
                    :style="{
                        maxHeight: scrollHeight + 'px',
                    }"
                >
                    <div
                        class="item"
                        v-for="item in queryFilteredList"
                        @click="showEditStage({ queryId: '' + item.id })"
                    >
                        <n-icon style="margin-right: 4px">
                            <IntegrationInstructionsFilled />
                        </n-icon>
                        <div class="label">{{ item.name }}</div>
                        <n-popconfirm
                            trigger="click"
                            placement="right"
                            @positive-click="deleteQuery(item.id)"
                        >
                            <template #trigger>
                                <n-button
                                    class="delete-btn"
                                    size="tiny"
                                    quaternary
                                    style="margin-left: auto"
                                    @click.stop
                                >
                                    <n-icon>
                                        <DeleteFilled />
                                    </n-icon>
                                </n-button>
                            </template>
                            确定删除该Query?（该操作不可撤销）
                        </n-popconfirm>
                    </div>
                </n-scrollbar>
            </n-tab-pane>
        </n-tabs>
    </div>
</template>

<style lang="less" scoped>
@import '../../../common/common.less';
.panel-query-list-view {
    width: 220px;
    background-color: #fff;
    overflow: hidden;
    resize: horizontal;
    .standard-border;
    .standard-shadow;

    .filter-bar-view {
        padding: 0 4px 6px;
    }

    .list {
        .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-weight: bold;
            padding: 4px 0 4px 10px;
            cursor: pointer;

            .label {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .delete-btn {
                display: none;
            }

            &:hover {
                background-color: #f8f8f8;

                .delete-btn {
                    display: block;
                }
            }
        }
    }
}
</style>

<script setup>
import { ref, computed } from 'vue';

import {
    NTabs,
    NTabPane,
    NScrollbar,
    NInput,
    NButton,
    NIcon,
    NPopconfirm,
    useMessage,
} from 'naive-ui';

import {
    InsertChartFilled,
    AddFilled,
    DeleteFilled,
    IntegrationInstructionsFilled,
} from '@vicons/material';

import { CostNervAPI } from '@/common/API';
import DragState from '@/common/DragState';

import { useModelEditorStore } from '@/stores/cost_nerv/ModelEditor';

const store = useModelEditorStore();

const message = useMessage();

const props = defineProps(['modelId']);

const emits = defineEmits(['panelClick', 'queryClick']);

let scrollHeight = ref(
    document.querySelector('.app-container').clientHeight - 230
);

/**
 * panel
 */

let panelList = computed(() => store.panelList);

let panelFilterWord = ref('');

let panelFilteredList = computed(() => {
    if (panelFilterWord.value === '') {
        return panelList.value;
    }
    return panelList.value.filter(item =>
        item.name.toLowerCase().includes(panelFilterWord.value)
    );
});

function showEditStage(item) {
    emits('panelClick', item);
}

function onPanelDragStart(e, data) {
    e.dataTransfer.effectAllowed = 'copy';
    DragState.setDragState({ opTarget: 'panel', data });
}

function onPanelDragEnd(e) {
    DragState.apply();
}

function deletePanel(id) {
    message.loading('操作中...', { duration: 0 });
    CostNervAPI.deletePanel(props.modelId, id).then(res => {
        message.destroyAll();
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        store.refresh(props.modelId, 'panel');
    });
}

/**
 * query
 */

let queryList = computed(() => store.queryList);

let queryFilterWord = ref('');

let queryFilteredList = computed(() => {
    if (queryFilterWord.value === '') {
        return queryList.value;
    }
    return queryList.value.filter(item =>
        item.name.toLowerCase().includes(queryFilterWord.value)
    );
});

function deleteQuery(id) {
    message.loading('操作中...', { duration: 0 });
    CostNervAPI.deleteQuery(props.modelId, id).then(res => {
        message.destroyAll();
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        store.refresh(props.modelId, 'query');
    });
}
</script>
