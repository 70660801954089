<template>
    <div
        :class="{
            'panel-column': true,
            enter: isEnter,
            bind: !isEnter && !!props.id,
        }"
        @dragenter="onPanelDragEnter"
        @dragleave="onPanelDragLeave"
        @dragover="e => e.preventDefault()"
    >
        <template v-if="isEnter"> 松开鼠标放置 </template>
        <template v-else-if="!props.id"> 拖拽Panel放置到栅格 </template>
        <template v-else>
            <div
                class="label"
                @click="onPanelLabelClick"
                draggable="true"
                @dragstart="onLabelDragStart"
                @dragend="onLabelDragEnd"
            >
                {{ panelInfo.name }}
            </div>
            <n-popconfirm
                trigger="click"
                placement="right"
                @positive-click="clearPanel"
            >
                <template #trigger>
                    <n-button size="tiny" quaternary>
                        <n-icon>
                            <ClearFilled />
                        </n-icon>
                    </n-button>
                </template>
                确定将此Panel从该栅格移除?
            </n-popconfirm>
        </template>
    </div>
</template>

<style lang="less" scoped>
.panel-column {
    padding: 12px 0;
    text-align: center;
    border: 1px dashed #eee;
    color: #999;
    font-size: 14px;
    line-height: 30px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &.enter {
        background-color: #f0fff0;
    }

    &.bind {
        border-style: solid;
        color: #000;
        font-weight: bold;
    }

    .label {
        padding: 0 2px 0 6px;
        cursor: pointer;
        border: 1px solid transparent;

        &:hover {
            border: 1px dashed #ccc;
        }
    }
}
</style>

<script setup>
import { ref, computed } from 'vue';
import { NButton, NIcon, NPopconfirm, useDialog } from 'naive-ui';
import { ClearFilled } from '@vicons/material';

import { useModelEditorStore } from '@/stores/cost_nerv/ModelEditor';

import DragState from '@/common/DragState';

const dialog = useDialog();

const store = useModelEditorStore();

const props = defineProps(['id', 'position']);

const emits = defineEmits(['update:id', 'panelClick']);

let panelInfo = computed(() => {
    let panel = store.panelList.find(item => item.id === props.id);
    return panel || { name: props.id };
});

let isEnter = ref(false);

function onBind(dragState) {
    isEnter.value = false;
    if (props.id) {
        dialog.warning({
            title: '警告',
            content: '确定覆盖该Panel?',
            positiveText: '确定',
            negativeText: '取消',
            onPositiveClick: () => {
                emits('update:id', dragState.data.id);
            },
        });
        return;
    }
    emits('update:id', dragState.data.id);
}

function onSwap(dragState) {
    isEnter.value = false;
    emits('update:id', dragState.data.id);
}

function onLabelDragStart(e) {
    e.dataTransfer.effectAllowed = 'move';
    DragState.setDragState({
        opTarget: 'panel_column',
        data: { id: props.id },
    });
}

function onLabelDragEnd(e) {
    let reactState = DragState.getReactState();
    if (!reactState) {
        return;
    }
    emits('update:id', reactState.data.id);
    DragState.apply();
}

function onPanelDragEnter(e) {
    if (
        !['panel', 'panel_column'].includes(DragState.getDragState().opTarget)
    ) {
        return;
    }
    DragState.setReactState({ data: { id: props.id } });
    DragState.setApplyEventListener(
        DragState.getDragState().opTarget === 'panel' ? onBind : onSwap
    );
    isEnter.value = true;
}

function onPanelDragLeave(e) {
    isEnter.value = false;
    let reactState = DragState.getReactState();
    // 发现拖拽event的触发时序不太稳定，可能会出现状态被误重置，所以加个判断，只有当state中记录的状态值与自身状态值匹配，才执行重置操作
    if (!reactState || reactState.data.id !== props.id) {
        return;
    }
    DragState.setReactState(null);
    DragState.setApplyEventListener(null);
}

function onPanelLabelClick() {
    emits('panelClick', panelInfo.value);
}

function clearPanel() {
    emits('update:id', null);
}
</script>
