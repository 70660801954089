/**
 * @file DragState
 * @description 基础的拖拽状态管理和拖拽行为响应
 */

let dragObject = null;

let reactObject = null;

let applyListener = null;

export default class DragState {
    static setDragState(state) {
        dragObject = state;
    }

    static getDragState() {
        return dragObject;
    }

    static setReactState(state) {
        reactObject = state;
    }

    static getReactState() {
        return reactObject;
    }

    static setApplyEventListener(listener) {
        applyListener = listener;
    }

    static apply() {
        applyListener && applyListener(dragObject, reactObject);
        setTimeout(() => {
            dragObject = {};
            reactObject = {};
            applyListener = null;
        }, 0);
    }
}
